import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { HttpLaravelHeaders } from '@app/_interceptors/url.interceptor';
import { DataList, ResponseObject } from '@app/_shared/interfaces';
import { CompanyUser, CompanyUserCreate, CompanyUserUpdate } from '@app/_shared/interfaces/http/company-user';

/**
 * A service class for managing company users.
 * @class
 * @public
 */
@Injectable({
  providedIn: 'root',
})
export class CompanyUsersService {
  private http = inject(HttpClient);

  private headers = new HttpLaravelHeaders();

  private endpoint = '/api/v1/user/companies/users';

  /**
   * Retrieves a list of data with company users.
   *
   * @param {HttpParams} params - The HTTP parameters for the request.
   * @return {Observable<DataList<CompanyUser, any>>} - An observable that represents the HTTP response containing the data list of company users along with any additional data.
   */
  index(params?: HttpParams) {
    return this.http.get<DataList<CompanyUser, any>>(this.endpoint, {
      headers: this.headers,
      params,
    });
  }

  store(data: CompanyUserCreate) {
    return this.http.post<ResponseObject<CompanyUser>>(this.endpoint, data, {
      headers: this.headers,
    });
  }

  patch(id: number, data: CompanyUserUpdate) {
    return this.http.patch<ResponseObject<CompanyUser>>(`${this.endpoint}/${id}`, data, {
      headers: this.headers,
    });
  }

  delete(id: number) {
    return this.http.delete(`${this.endpoint}/${id}`, {
      headers: this.headers,
    });
  }
}
